<template>
  <div>
    <div class="card p-3 mb-3 shadow-sm">
      <div class="row justify-content-between">
        <div class="col-auto">
          <h5 class="e-text-red mb-0">
            Corrective Action Report
          </h5>
        </div>
      </div>
      <div class="card p-3 mb-3 shadow-sm">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h6 class="e-text-red mb-0">
              Non-Conformity
            </h6>
          </div>
        </div>
        <div class="card p-3 mb-3 shadow-sm text-center" v-if="nonConformityType[$route.params.type] === null">
          No Data
        </div>
        <template v-else>
          <template v-for="(ncr, index) in nonConformityType[$route.params.type]">
            <div class="py-3 card rounded-0 shadow-sm cursor-pointer no-select mt-2" @click="toggleNcrArrows(index)">
              <div class="d-flex justify-content-between">
                <div class="d-flex w-100">
                  <div class="pl-3 font-weight-bold mr-2">
                  <span class="nc_title">
                    NC {{ index + 1 }} :
                  </span>
                  </div>
                  <div class="pl-3 font-weight-bold mr-2" style="word-break: break-word; hyphens: auto; width: 80%">
                    <span class="nc_title"> <i @click.stop="viewNonConformity(ncr.id, ncr.type)" class="nc_oe_title">{{ ncr.description | stripHtmlTags }} </i></span>
                  </div>
                </div>
                <div class="pr-3" v-if="ncr.objective_evidences.length > 0">
                  <!--                <font-awesome-icon v-if="ncrArrows[index] === true" icon="chevron-up" style="font-size: 20px"/>-->
                  <!--                <font-awesome-icon v-else icon="chevron-down" style="font-size: 20px"/>-->
                  <font-awesome-icon icon="bars" style="font-size: 20px"/>
                </div>
              </div>
            </div>
            <template v-if="ncrArrows[index]">
              <div v-for="(oe, oe_index) in ncr.objective_evidences">
                <div class="card rounded-0 shadow-sm border-top-0">
                  <div class="ml-3">
                    <div class="px-2 py-3 font-weight-bold cursor-pointer no-select" @click="toggleNcrOeArrows(index, oe_index)">
                      <span class="">OE {{ oe_index + 1 }} : <i @click="viewObjectiveEvidence(ncr.id, ncr.type, oe.id)" class="nc_oe_title">{{ oe.description | stripHtmlTags }} </i></span>
                      <div style="float:right;" class="mr-2">
                        <font-awesome-icon v-if="ncrOeArrows[index][oe_index] === true" icon="chevron-up"/>
                        <font-awesome-icon v-else icon="chevron-down"/>
                      </div>
                    </div>
                    <template v-if="ncrOeArrows[index][oe_index] === true">
                      <table class="table table-sm table-bordered w-100">
                        <tr>
                          <th class="bg-light align-middle" style="width: 100px">
                            <p style="transform: rotate(270deg)">BEFORE</p>
                          </th>
                          <td style="width: 45%">
                            <div style="max-height: 60vh; overflow-y: auto">
                              <template v-if="oe.before.length > 0">
                                <template v-for="(image, index) in oe.before">
                                  <template v-if="image.image_url.startsWith('http') === false">
                                    <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                         @click="viewImage(index, oe.before, {
                                                                 'path'      : 'image_url',
                                                                 'caption'   : 'name'
                                                           })">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                          <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                          <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                            <div class="centered-element-in-div">
                                              <app-loading
                                                :spinner-only="true"
                                                :height="'100px'"
                                                :width="'100px'"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </template>
                              <template v-else>
                                <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                      <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div" style="width: max-content">
                                          No Image Attached
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </td>
                          <th class="bg-light align-middle" style="width: 100px">
                            <p style="transform: rotate(270deg)">AFTER</p>
                          </th>
                          <td style="width: 45%">
                            <div style="max-height: 60vh; overflow-y: auto">
                              <template v-if="oe.after.length > 0">
                                <template v-for="(image, index) in oe.after">
                                  <template v-if="image.image_url.startsWith('http') === false">
                                    <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                         @click="viewImage(index, oe.after, {
                             'path'      : 'image_url',
                             'caption'   : 'name'
                       })">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                          <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                          <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                            <div class="centered-element-in-div">
                                              <app-loading
                                                :spinner-only="true"
                                                :height="'100px'"
                                                :width="'100px'"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </template>
                              <template v-else>
                                <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                      <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div" style="width: max-content">
                                          No Image Attached
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div class="pl-3 mb-3 w-100">
                        <i>
                          Rectified Date: {{ oe.rectified_date.human ?? ''}} - {{  oe.rectified_comment ?? '(with any comment)' | stripHtmlTags }}
                        </i>
                      </div>
                    </template>
                  </div>
                </div>
              </div>



            </template>





          </template>
        </template>
      </div>

      <div class="card p-3 mb-3 shadow-sm">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h6 class="e-text-red mb-0">
              Observation Note
            </h6>
          </div>
        </div>
        <div class="card p-3 mb-3 shadow-sm text-center" v-if="observationNoteType[$route.params.type] === null">
          No Data
        </div>
        <template v-else>
          <template v-for="(ncr, index) in observationNoteType[$route.params.type]">
            <div class="py-3 card rounded-0 shadow-sm cursor-pointer no-select mt-2" @click="toggleObsArrows(index)">
              <div class="d-flex justify-content-between">
                <div class="d-flex w-100">
                  <div class="pl-3 font-weight-bold mr-2">
                  <span class="nc_title">
                    OBS {{ index + 1 }} :
                  </span>
                  </div>
                  <div class="pl-3 font-weight-bold mr-2" style="word-break: break-word; hyphens: auto; width: 80%">
                    <span class="nc_title"> <i @click.stop="viewNonConformity(ncr.id, ncr.type)" class="nc_oe_title">{{ ncr.description | stripHtmlTags }} </i></span>
                  </div>
                </div>
                <div class="pr-3" v-if="ncr.objective_evidences.length > 0">
                  <!--                <font-awesome-icon v-if="ncrArrows[index] === true" icon="chevron-up" style="font-size: 20px"/>-->
                  <!--                <font-awesome-icon v-else icon="chevron-down" style="font-size: 20px"/>-->
                  <font-awesome-icon icon="bars" style="font-size: 20px"/>
                </div>
              </div>
            </div>
            <template v-if="obsArrows[index]">
              <div v-for="(oe, oe_index) in ncr.objective_evidences">
                <div class="card rounded-0 shadow-sm border-top-0">
                  <div class="ml-3">
                    <div class="px-2 py-3 font-weight-bold cursor-pointer no-select" @click="toggleObsOeArrows(index, oe_index)">
                      <span class="">OE {{ oe_index + 1 }} : <i @click="viewObjectiveEvidence(ncr.id, ncr.type, oe.id)" class="nc_oe_title">{{ oe.description | stripHtmlTags }} </i></span>
                      <div style="float:right;" class="mr-2">
                        <font-awesome-icon v-if="obsOeArrows[index][oe_index] === true" icon="chevron-up"/>
                        <font-awesome-icon v-else icon="chevron-down"/>
                      </div>
                    </div>
                    <template v-if="obsOeArrows[index][oe_index] === true">
                      <table class="table table-sm table-bordered w-100">
                        <tr>
                          <th class="bg-light align-middle" style="width: 100px">
                            <p style="transform: rotate(270deg)">BEFORE</p>
                          </th>
                          <td style="width: 45%">
                            <div style="max-height: 60vh; overflow-y: auto">
                              <template v-if="oe.before.length > 0">
                                <template v-for="(image, index) in oe.before">
                                  <template v-if="image.image_url.startsWith('http') === false">
                                    <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                         @click="viewImage(index, oe.before, {
                             'path'      : 'image_url',
                             'caption'   : 'name'
                       })">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                          <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                          <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                            <div class="centered-element-in-div">
                                              <app-loading
                                                :spinner-only="true"
                                                :height="'100px'"
                                                :width="'100px'"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </template>
                              <template v-else>
                                <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                      <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div" style="width: max-content">
                                          No Image Attached
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </td>
                          <th class="bg-light align-middle" style="width: 100px">
                            <p style="transform: rotate(270deg)">AFTER</p>
                          </th>
                          <td style="width: 45%">
                            <div style="max-height: 60vh; overflow-y: auto">
                              <template v-if="oe.after.length > 0">
                                <template v-for="(image, index) in oe.after">
                                  <template v-if="image.image_url.startsWith('http') === false">
                                    <div style="float: left" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0"
                                         @click="viewImage(index, oe.after, {
                             'path'      : 'image_url',
                             'caption'   : 'name'
                       })">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" id="ia-attachment-card" class="card p-4">
                                          <div :style="{ minWidth: '50%', backgroundImage: 'url(' + image.image_url + ')', width: 'auto', height: '230.5px' }" class="background-contain">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div style="float: left;" data-v-71fad98a="" class="col-md-6 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                      <div class="p-2">
                                        <div data-v-16e2512b="" data-v-71fad98a="" class="card p-4">
                                          <div :style="{ minWidth: '50%', width: 'auto', height: '230.5px' }" class="background-contain">
                                            <div class="centered-element-in-div">
                                              <app-loading
                                                :spinner-only="true"
                                                :height="'100px'"
                                                :width="'100px'"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </template>
                              <template v-else>
                                <div data-v-71fad98a="" class="col-md-12 col-sm-12 mb-1 justify-content-center align-content-center cursor-pointer p-0">
                                  <div class="p-2">
                                    <div data-v-16e2512b="" data-v-71fad98a="" class="p-4">
                                      <div :style="{ minWidth: '100%%', width: 'auto', height: '230.5px' }" class="background-contain">
                                        <div class="centered-element-in-div" style="width: max-content">
                                          No Image Attached
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <div class="pl-3 mb-3 w-100">
                        <i>
                          Rectified Date: {{ oe.rectified_date.human ?? ''}} - {{  oe.rectified_comment ?? '(with any comment)' | stripHtmlTags }}
                        </i>
                      </div>
                    </template>
                  </div>
                </div>
              </div>



            </template>





          </template>
        </template>
      </div>


    </div>
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    >
    </image-slider>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import NonConformityMixin from "@/mixins/NonConformityMixin";
import ObjectiveEvidenceMixin from "@/mixins/ObjectiveEvidenceMixin";
import AppLoading from "@/components/elements/AppLoading.vue";

export default {
  name: "IaCorrectiveActionReport",
  components: {AppLoading, ImageSlider},
  mixins : [ImageSliderMixin, NonConformityMixin, ObjectiveEvidenceMixin],
  data() {
    return {
      ncrArrows : [],
      ncrOeArrows : [],
      obsArrows : [],
      obsOeArrows : []
    }
  },
  methods : {
    async viewNonConformity(ncrId, ncrType) {
      await this.$router.push(
        {
          name: 'NcrMainDetails',
          params: {
            id : this.$route.params.id,
            vessel_id : this.$route.params.vessel_id,
            type : this.$route.params.type,
            ncr_id : ncrId,
            ncr_type : ncrType
          },
        },

      ).catch(() => {})
    },
    async viewObjectiveEvidence(ncrId, ncrType, oeId) {
      await this.$router.push(
        {
          name: 'OEMainDetails',
          params: {
            id : this.$route.params.id,
            vessel_id : this.$route.params.vessel_id,
            type : this.$route.params.type,
            ncr_type : ncrType,
            ncr_id : ncrId,
            oe_id : oeId,
          },
        },
      ).catch(() => {})
    },
    toggleNcrArrows(index) {
      this.$set(this.ncrArrows, index, !this.ncrArrows[index]);
    },
    async toggleNcrOeArrows(index, oeIndex) {
      const openedContainer = !this.ncrOeArrows[index][oeIndex];
      this.$set(this.ncrOeArrows[index], oeIndex, openedContainer);

      if (openedContainer === true) {
        let beforeImages = this.nonConformityType[this.$route.params.type][index].objective_evidences[oeIndex].before;
        let afterImages = this.nonConformityType[this.$route.params.type][index].objective_evidences[oeIndex].after;

        let beforeImagesUrls = beforeImages.filter(item => item.image_url.startsWith('http'));
        let afterImagesUrls = afterImages.filter(item => item.image_url.startsWith('http'));

        if (beforeImagesUrls.length > 0) {
          beforeImages = await this.convertImagesToBase645(beforeImages);
        }

        if (afterImagesUrls.length > 0) {
          afterImages = await this.convertImagesToBase645(afterImages);
        }
      }
    },
    toggleObsArrows(index) {
      this.$set(this.obsArrows, index, !this.obsArrows[index]);
    },
    async toggleObsOeArrows(index, oeIndex) {
      const openedContainer = !this.obsOeArrows[index][oeIndex];
      this.$set(this.obsOeArrows[index], oeIndex, !this.obsOeArrows[index][oeIndex]);

      if (openedContainer === true) {
        let beforeImages = this.observationNoteType[this.$route.params.type][index].objective_evidences[oeIndex].before;
        let afterImages = this.observationNoteType[this.$route.params.type][index].objective_evidences[oeIndex].after;

        let beforeImagesUrls = beforeImages.filter(item => item.image_url.startsWith('http'));
        let afterImagesUrls = afterImages.filter(item => item.image_url.startsWith('http'));
        if (beforeImagesUrls.length > 0) {
          beforeImages = await this.convertImagesToBase645(beforeImages);
        }

        if (afterImagesUrls.length > 0) {
          afterImages = await this.convertImagesToBase645(afterImages);
        }
      }

    },
  },
  computed : {
    ...mapGetters([
      'nonConformities',
    ])
  },
  filters : {
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  },
  created() {
    const ncr = this.nonConformityType[this.$route.params.type];
    if (ncr !== null) {
      this.ncrArrows = new Array(ncr.length).fill(false);
      for (let counter = 0; counter < ncr.length; counter++) {
        const objectiveEvidenceLength = ncr[counter].objective_evidences.length;
        this.ncrOeArrows.push(
          new Array(objectiveEvidenceLength).fill(false)
        )
      }
    }

    const obs = this.observationNoteType[this.$route.params.type];
    if (obs !== null) {
      this.obsArrows = new Array(obs.length).fill(false);
      for (let counter = 0; counter < obs.length; counter++) {
        const objectiveEvidenceLength = obs[counter].objective_evidences.length;
        this.obsOeArrows.push(
          new Array(objectiveEvidenceLength).fill(false)
        )
      }
    }
  }
}
</script>

<style scoped>
.nc_oe_title:hover {
  color: #d3313c !important;
  cursor: pointer;
  user-select: none;
}

.nc_title {
  font-size: 16px;
}

.oe_title {
  font-size: 15px;
}

#ia-attachment-card:hover {
  background-color: #dae8f4;
  transition: 0.5s;
}
</style>
